var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('hr'),_c('div',{staticClass:"fw-bold"},[_vm._v("Réservations")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"}),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-warning",on:{"click":function($event){return _vm.refresh()}}},[_vm._v(" Actualiser ")])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-warning",on:{"click":function($event){return _vm.print()}}},[_vm._v(" Imprimer ")])]),(parseFloat(_vm.subscription.amountToPay) > 0 && parseFloat(_vm.subscription.totalPaid) < parseFloat(_vm.subscription.amountToPay))?_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.$router.push({
        name: 'payments-create',
        params: {
          reference: _vm.subscription.field_reference
        },query: {
          subscription:_vm.subscription.reference
        }
        
      })}}},[_c('i',{staticClass:"bi bi-plus-circle"}),_vm._v(" Ajouter Paiements ")])]):_vm._e(),(_vm.subscription.totalPaid > 0)?_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.$router.push({
         name:'reservations-professionals-create',
        query: {
          fieldReference: _vm.subscription.field_reference,
          subscription:_vm.subscription.reference
        }
        
      })}}},[_c('i',{staticClass:"bi bi-plus-circle"}),_vm._v(" Ajouter Reservations ")])]):_vm._e(),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.removeSubscription(_vm.subscription.reference)}}},[_vm._v(" Supprimer ")])])]),_c('div',{staticClass:"row align-items-stratch"},[_c('div',{staticClass:"col-xl-3 col-6 my-3"},[_c('label',{attrs:{"for":""}},[_vm._v(" Date ")]),_c('div',[_vm._v(" "+_vm._s(_vm.subscription.date)+" ")])]),_c('div',{staticClass:"col-xl-3 col-6 my-3"},[_c('label',{attrs:{"for":""}},[_vm._v(" Heure ")]),_c('div',[_vm._v(" "+_vm._s(_vm.subscription.startTime)+" ")])])]),_c('div',{staticClass:"fw-bold"},[_vm._v("Informations client")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3 col-6 my-3 my-3"},[_c('label',{attrs:{"for":""}},[_vm._v(" Prénom : ")]),(_vm.subscription.field)?_c('div',[_vm._v(" "+_vm._s(_vm.subscription.field.firstName)+" ")]):_vm._e()]),_c('div',{staticClass:"col-xl-3 col-6 my-3"},[_c('label',{attrs:{"for":""}},[_vm._v(" Nom : ")]),(_vm.subscription.field)?_c('div',[_vm._v(" "+_vm._s(_vm.subscription.field.lastName)+" ")]):_vm._e()]),_c('div',{staticClass:"col-xl-3 col-6 my-3"},[_c('label',{attrs:{"for":""}},[_vm._v("Téléphone : ")]),(_vm.subscription.field)?_c('div',[_vm._v(" "+_vm._s(_vm.subscription.field.phone)+" ")]):_vm._e()])]),_c('br'),_c('table',{staticClass:"table table-striped table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.subscription.bookings),function(booking,index){return _c('tr',{key:index,class:{ ' text-danger': _vm.isPastDue(booking.date, booking.startTime),
          'text-success': !_vm.isPastDue(booking.date, booking.startTime)
         }},[_c('th',[_vm._v(_vm._s(index + 1))]),_c('th',[_c('button',{staticClass:"btn btn-warning",on:{"click":function($event){return _vm.$router.push({
              name: 'reservations-details',
              params: { reference: booking.booking_reference }
            })}}},[_vm._v(" Afficher Réservation ")])]),_c('th',[_vm._v(_vm._s(_vm.formatDate(booking.date)))]),_c('th',[_vm._v(_vm._s(booking.startTime))]),_c('th',[_vm._v(_vm._s(booking.endTime))]),_c('th',[_vm._v(_vm._s(booking.remark))]),_c('th',[_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.remove(booking.reference)}}},[_vm._v(" Annuler ")])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v(" Réservation ")]),_c('th',[_vm._v(" Date ")]),_c('th',[_vm._v("Time tart")]),_c('th',[_vm._v("Time end")]),_c('th',[_vm._v("Remarque ")]),_c('th')])])
}]

export { render, staticRenderFns }