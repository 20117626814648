<template>
  <div>
    <hr />
    <div class="fw-bold">Réservations</div>

    <div class="row">
      <div class="col"></div>
      <div class="col-auto">
        <button @click="refresh()" class="btn btn-warning">

          Actualiser
        </button>
      </div> 

      <div class="col-auto">
        <button @click="print()" class="btn btn-warning">

          Imprimer
        </button>
      </div> 
      <div v-if="parseFloat(subscription.amountToPay) > 0 && parseFloat(subscription.totalPaid) < parseFloat(subscription.amountToPay)"
         class="col-auto">
        <button  @click="$router.push({
          name: 'payments-create',
          params: {
            reference: subscription.field_reference
          },query: {
            subscription:subscription.reference
          }
          
        })" 
          class="btn btn-secondary">
          <i class="bi bi-plus-circle"></i>
          Ajouter Paiements
        </button>
      </div>

      <div v-if="subscription.totalPaid > 0" class="col-auto">
        <button  @click="$router.push({
           name:'reservations-professionals-create',
          query: {
            fieldReference: subscription.field_reference,
            subscription:subscription.reference
          }
          
        })" 
          class="btn btn-secondary">
          <i class="bi bi-plus-circle"></i>
          Ajouter Reservations
        </button>
      </div>

      <div class="col-auto">
        <button @click="removeSubscription(subscription.reference)" class="btn btn-danger">
          Supprimer
        </button>
      </div> 

    </div>

    <div class="row align-items-stratch">
      <div class="col-xl-3 col-6 my-3">
        <label for=""> Date </label>
        <div>
          {{ subscription.date }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for=""> Heure </label>
        <div>
          {{ subscription.startTime }}
        </div>
      </div>
    </div>

    
   
    <div class="fw-bold">Informations client</div>
    <div class="row">
      <div class="col-xl-3 col-6 my-3 my-3">
        <label for=""> Prénom : </label>
        <div v-if="subscription.field">
          {{ subscription.field.firstName }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for=""> Nom : </label>
        <div v-if="subscription.field">
          {{ subscription.field.lastName }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for="">Téléphone : </label>
        <div v-if="subscription.field">
          {{ subscription.field.phone }}
        </div>
      </div>
  </div>

  <br>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
            
            <th></th>
            
            <th> Réservation </th>
            <th> Date </th>
            
            <th>Time tart</th>
            <th>Time end</th>
            <th>Remarque  </th>
            <th>  </th>
          </tr>
      </thead>
      <tbody>
        <tr
          v-for="(booking, index) in subscription.bookings"
          :key="index"
          :class="{ ' text-danger': isPastDue(booking.date, booking.startTime),
            'text-success': !isPastDue(booking.date, booking.startTime)
           }"
        >
          <th>{{ index + 1 }}</th>
          <th>
            <button
              @click="$router.push({
                name: 'reservations-details',
                params: { reference: booking.booking_reference }
              })"
              class="btn btn-warning"
            >
              Afficher Réservation 
            </button>
          </th>
          <th>{{ formatDate(booking.date) }}</th>
          <th>{{ booking.startTime }}</th>
          <th>{{ booking.endTime }}</th>
          <th>{{ booking.remark }}</th>
          <th>
            <button @click="remove(booking.reference)" class="btn btn-danger">
              Annuler
            </button>
          </th>
        </tr>
      </tbody>

    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("subscription", {
      subscription: "getSubscription",
    }),
   
  },
  methods: {
    async print() {
      await this.$store.dispatch("subscription/print",this.$route.params.reference);
    }, 
    async removeSubscription(reference) {
      await this.$store.dispatch("subscription/destroy",reference);
    },
    async refresh() {
      await this.$store.dispatch("subscription/show",this.$route.params.reference);
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      // Get the day name in French
      const dayName = new Intl.DateTimeFormat('fr-FR', { weekday: 'long' })
        .format(date);

      // Format the date parts
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();

      return `${dayName} ${day} - ${month} - ${year}`;
    },
    async remove(reference) {
      await this.$store.dispatch("reservation/destroy", reference);
    },
    isPastDue(date, time) {
      const bookingDateTime = new Date(`${date}T${time}`);
      const now = new Date();
      return bookingDateTime <= now;
    },
  },
  async beforeMount() {
    await this.$store.dispatch("subscription/show",this.$route.params.reference);

  },
};
</script>
